<!-- fancy context wrapper -->

<template>
  <slot></slot>
</template>

<script setup lang="ts" generic="T extends any[]">
import { useReorderableList } from ".";

const model = defineModel<T>({ required: true });

const props = defineProps<{
  axis?: "vertical" | "horizontal";
}>();

useReorderableList(model, { axis: props.axis });
</script>
